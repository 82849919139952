import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PatientDetail from '../components/PatientDetail';
import api from '../api'; // Importar a API real
import '../styles/PatientDetail.css';

const PatientDetailPage = () => {
  const { patientId } = useParams();
  const [patient, setPatient] = useState(null);

  useEffect(() => {
    const fetchPatientDetails = async () => {
      try {
        const response = await api.get(`/api/radiografias/${patientId}`);
        console.log('Dados do paciente:', response.data);
        setPatient(response.data);
      } catch (error) {
        console.error('Erro ao buscar detalhes do paciente:', error);
      }
    };
  
    fetchPatientDetails();
  }, [patientId]); // Dependência para o useEffect

  return (
    <div className="patient-detail">
      {patient ? <PatientDetail patient={patient} /> : <p>Carregando...</p>}
    </div>
  );
};

export default PatientDetailPage;
