import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import '../styles/PatientDetail.css';
import { useNavigate } from 'react-router-dom';
import 'react-image-lightbox/style.css';

const PatientDetail = ({ patient }) => {
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  let imagensArray = [];

  if (patient?.imagens) {
    if (Array.isArray(patient.imagens)) {
      // Se já for um array
      imagensArray = patient.imagens;
    } else if (typeof patient.imagens === 'string') {
      // Se for uma string
      try {
        imagensArray = JSON.parse(patient.imagens);
      } catch (e) {
        console.error('Erro na análise do JSON:', e);
        // Tentar tratar como uma string de URLs separadas por vírgulas
        imagensArray = patient.imagens.split(',');
      }
    }
  }

  const openImageModal = (url) => {
    setSelectedImage(url);
    setIsOpen(true);
  };

  return (
    <div className="patient-detail-container">
      <button onClick={() => navigate('/pacientes')} className="btn-voltar">Voltar</button>
      <div className="patient-detail">
        <div className="patient-detail-header">
          <h2>{patient?.nomePaciente}</h2>
        </div>
        <div className="patient-detail-content">
          <p>{patient?.observacoes}</p>
          <div className="radiograph-grid">
            {imagensArray.map((url, index) => (
              <img
                key={index}
                src={url}
                alt={`Radiografia de ${patient?.nomePaciente}`}
                onClick={() => openImageModal(url)}
              />
            ))}
          </div>
        </div>
      </div>
      {isOpen && (
        <Lightbox
          mainSrc={selectedImage}
          onCloseRequest={() => setIsOpen(false)}
          enableZoom={true}
          imageTitle={`Radiografia de ${patient?.nomePaciente}`}
        />
      )}
    </div>
  );
};

export default PatientDetail;
