import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import api from '../api';
import { Link } from 'react-router-dom';

const AgendaWidget = ({ classes }) => {
  const [currentAppointment, setCurrentAppointment] = useState(null);
  const [nextAppointment, setNextAppointment] = useState(null);

  useEffect(() => {
    api.get('/api/consultas')
      .then(response => {
        const consultas = response.data;
        if (consultas.length > 0) {
          setCurrentAppointment(consultas[0]);
          if (consultas.length > 1) {
            setNextAppointment(consultas[1]);
          }
        }
      })
      .catch(error => console.error('Erro ao buscar consultas', error));
  }, []);

  const formatDateTime = (isoString) => {
    const date = new Date(isoString);
    return `${date.toLocaleTimeString('pt-BR', { timeStyle: 'short' })} ${date.toLocaleDateString('pt-BR', { dateStyle: 'short' })}`;
  };

  return (
    <Card className={classes.widgetCard}>
      <CardContent>
        <Link to="/agenda" className={classes.link}>
          <Typography variant="h5" className={classes.widgetTitle}>
            Atendimentos
          </Typography>
        </Link>
        {currentAppointment ? (
          <div>
            <Typography className={classes.body2}>
              Atual: {currentAppointment.nomePaciente}
            </Typography>
            <Typography className={classes.body2}>
              Serviço: {currentAppointment.service}
            </Typography>
            <Typography className={classes.body2}>
              Horário: {formatDateTime(currentAppointment.dataHora)}
            </Typography>
          </div>
        ) : (
          <Typography className={classes.body2}>Nenhum atendimento atual.</Typography>
        )}
        <hr className={classes.customHr} />
        {nextAppointment ? (
          <div>
            <Typography className={classes.body3}>
              Próximo: {nextAppointment.nomePaciente}
            </Typography>
            <Typography className={classes.body3}>
              Serviço: {nextAppointment.service}
            </Typography>
            <Typography className={classes.body3}>
              Horário: {formatDateTime(nextAppointment.dataHora)}
            </Typography>
          </div>
        ) : (
          <Typography className={classes.body3}>Nenhum próximo atendimento.</Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default AgendaWidget;
