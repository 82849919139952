// pages/Consultas/AddEditConsulta.js
import React, { useState } from 'react';
import api from '../api';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';
import DatePicker, { registerLocale } from 'react-datepicker'; // Importe registerLocale corretamente
import ptBR from 'date-fns/locale/pt-BR'; // Importação do locale pt-BR
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/AddEditConsulta.css';

registerLocale('pt-BR', ptBR);

const AddEditConsulta = () => {
  const [formData, setFormData] = useState({
    nomePaciente: '',
    service: '',
    dataHora: new Date(),
    observacoes: '',
  });
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(''); // Adicionado
  const [errorMessage, setErrorMessage] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      dataHora: date,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      nomePaciente: formData.nomePaciente,
      service: formData.service,
      dataHora: formData.dataHora.toISOString(),
      observacoes: formData.observacoes,
      status: 'pendente' // Define o status inicial como 'pendente'
    };

    try {
      await api.post('/api/consultas', data);
      toast.success('Consulta agendada com sucesso!');
      setSuccessMessage("Consulta agendada com sucesso!");
    } catch (error) {
      console.error('Erro ao adicionar consulta:', error);
      setIsLoading(false);
    }
  };

  return (
    <div className="add-edit-consulta-container">
        <button onClick={() => navigate('/')} className="btn-voltar">Voltar</button>
        <div className={`toast ${successMessage ? 'show' : ''} success`}>
            {successMessage}
        </div>

        <div className={`toast ${errorMessage ? 'show' : ''} error`}>
            {errorMessage}
        </div>
      <h1>{id ? 'Editar Consulta' : 'Agendar Consulta'}</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Nome do Paciente:</label>
          <input
            type="text"
            name="nomePaciente"
            value={formData.nomePaciente}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Serviço:</label>
          <input
            type="text"
            name="service"
            value={formData.service}
            onChange={handleInputChange}
          />
        </div>
        <div>
        <label>Data e Hora:</label>
        <DatePicker
          selected={formData.dataHora}
          onChange={handleDateChange}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          timeCaption="Hora"
          dateFormat="dd/MM/yyyy HH:mm"
          locale="pt-BR" 
        />
      </div>
        <div>
          <label>Observações:</label>
          <textarea
            name="observacoes"
            value={formData.observacoes}
            onChange={handleInputChange}
          />
        </div>
        <button type="submit" disabled={isLoading} className="btn-save-protesa">
    {isLoading ? "Agendando..." : "Salvar Agendamento"}
     </button>
      </form>
    </div>
  );
};

export default AddEditConsulta;
