import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, TextField, Button } from '@material-ui/core';
import Pagination from '../components/Pagination';
import { fetchProcedimentos } from '../apiMock'; // Substitua pela sua função real de API
import '../styles/ProceduresReport.css';
import { useNavigate } from 'react-router-dom';

const ProceduresReport = () => {
  const [procedimentos, setProcedimentos] = useState([]);
  const [contagemHoje, setContagemHoje] = useState(0);
  const [contagemSemana, setContagemSemana] = useState(0);
  const [contagemMes, setContagemMes] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [procedimentosPorPagina] = useState(10);
  const [dataInicial, setDataInicial] = useState('');
  const [dataFinal, setDataFinal] = useState('');
  const navigate = useNavigate();

  // Obtenha os procedimentos para a página atual
  const getCurrentProcedimentos = () => {
    // Verifica se procedimentos está definido e tem elementos
    if (!procedimentos || procedimentos.length === 0) {
      return [];
    }
    const indexOfLastProc = currentPage * procedimentosPorPagina;
    const indexOfFirstProc = indexOfLastProc - procedimentosPorPagina;
    return procedimentos.slice(indexOfFirstProc, indexOfLastProc);
  };

    // Função para filtrar procedimentos por período
    const filtrarPorPeriodo = () => {
        let filtrados = procedimentos;
        if (dataInicial) filtrados = filtrados.filter(p => new Date(p.data) >= new Date(dataInicial));
        if (dataFinal) filtrados = filtrados.filter(p => new Date(p.data) <= new Date(dataFinal));
        setProcedimentos(filtrados);
      };

      useEffect(() => {
        const fetchData = async () => {
          const data = await fetchProcedimentos();
          setProcedimentos(data);
          calcularContagens(data);
        };
    
        fetchData();
      }, []);

        // Adicione a lógica de paginação e filtragem de datas aqui
  const handlePageChange = (page) => {
    setCurrentPage(page);
    // Aqui você pode chamar uma função para atualizar os procedimentos exibidos com base na página atual
  };

  const filtrarPorData = () => {
    // Sua lógica para filtrar procedimentos por data
  };

  const calcularContagens = (dados) => {
    const hoje = new Date();
    setContagemHoje(procedimentos.filter(proc => {
      const dataProcedimento = new Date(proc.data);
      return dataProcedimento.toDateString() === hoje.toDateString();
    }).length);
    setContagemSemana(procedimentos.filter(proc => {
      const dataProcedimento = new Date(proc.data);
      return isThisWeek(dataProcedimento);
    }).length);
    setContagemMes(procedimentos.filter(proc => {
      const dataProcedimento = new Date(proc.data);
      return dataProcedimento.getMonth() === hoje.getMonth() &&
             dataProcedimento.getFullYear() === hoje.getFullYear();
    }).length);
  };
    
  const isToday = (someDate) => {
    const today = new Date();
    return someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear();
  };
  
  const isThisWeek = (someDate) => {
    const today = new Date();
    const firstDayOfWeek = today.getDate() - today.getDay();
    const lastDayOfWeek = firstDayOfWeek + 6;
  
    const firstDateOfWeek = new Date(today.setDate(firstDayOfWeek));
    firstDateOfWeek.setHours(0, 0, 0, 0);
  
    const lastDateOfWeek = new Date(today.setDate(lastDayOfWeek));
    lastDateOfWeek.setHours(23, 59, 59, 999);
  
    return someDate >= firstDateOfWeek && someDate <= lastDateOfWeek;
  };
  
  return (
    <Card className="card">
      <button onClick={() => navigate('/')} className="btn-voltar">Voltar</button>
      <CardContent>
        <Typography variant="h5" component="h2">
          Relatório de Procedimentos
        </Typography>
        <Typography style={{ fontSize: '1.4rem', fontWeight: '600', fontFamily: "'Poppins', sans-serif" }}>
          Hoje: {contagemHoje}
        </Typography>
        <Typography style={{ fontSize: '1.4rem', fontWeight: '600', fontFamily: "'Poppins', sans-serif" }}>
          Esta Semana: {contagemSemana}
        </Typography>
        <Typography style={{ fontSize: '1.4rem', fontWeight: '600', fontFamily: "'Poppins', sans-serif" }}>
          Este Mês: {contagemMes}
        </Typography>
        <div>
          <TextField
            label="Data Inicial"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={dataInicial}
            onChange={(e) => setDataInicial(e.target.value)}
          />
          <TextField
            label="Data Final"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={dataFinal}
            onChange={(e) => setDataFinal(e.target.value)}
          />
          <Button onClick={filtrarPorData}>Buscar</Button>
        </div>
        <div>
        {getCurrentProcedimentos().map((proc, index) => (
            <div key={index}>
              <Typography variant="body2" component="p">
                Paciente: {proc.nomePaciente}
              </Typography>
              <Typography variant="body2" component="p">
                Procedimento: {proc.procedimento}
              </Typography>
              <Typography variant="body2" component="p">
                Data: {new Date(proc.data).toLocaleDateString()}
              </Typography>
            </div>
          ))}
        </div>
        <Pagination
          currentPage={currentPage}
          totalCount={procedimentos ? procedimentos.length : 0}
          pageSize={procedimentosPorPagina}
          onPageChange={handlePageChange}
        />
      </CardContent>
    </Card>
  );
};

export default ProceduresReport;
