// Pagination.js
import React from 'react';
import '../styles/Pagination.css';

const Pagination = ({ reportsPerPage, totalReports, paginate, currentPage }) => {
  const pageNumbers = [];
  
  for (let i = 1; i <= Math.ceil(totalReports / reportsPerPage); i++) {
    pageNumbers.push(i);
  }
  
  return (
    <nav>
      <div className='pagination'>
        <button 
          onClick={() => currentPage > 1 && paginate(currentPage - 1)} 
          className='page-button'
          disabled={currentPage === 1}
        >
          Anterior
        </button>
        {pageNumbers.map(number => (
          <span 
            key={number} 
            className={`page-number ${currentPage === number ? 'active' : ''}`}
            onClick={() => paginate(number)}
          >
            {number}
          </span>
        ))}
        <button 
          onClick={() => currentPage < pageNumbers.length && paginate(currentPage + 1)} 
          className='page-button'
          disabled={currentPage === pageNumbers.length}
        >
          Próximo
        </button>
      </div>
    </nav>
  );
};

export default Pagination;
