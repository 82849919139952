import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

// Função mock para simular chamada de API
const fetchProcedureData = () => {
  return Promise.resolve({
    daily: 5, // Exemplo: 5 procedimentos hoje
    weekly: 20, // Exemplo: 20 procedimentos nesta semana
    monthly: 80 // Exemplo: 80 procedimentos neste mês
  });
};

const ProceduresWidget = ({ classes }) => {
  const [dailyProcedures, setDailyProcedures] = useState(0);
  const [weeklyProcedures, setWeeklyProcedures] = useState(0);
  const [monthlyProcedures, setMonthlyProcedures] = useState(0);

  useEffect(() => {
    fetchProcedureData().then(data => {
      setDailyProcedures(data.daily);
      setWeeklyProcedures(data.weekly);
      setMonthlyProcedures(data.monthly);
    });
  }, []);

  return (
    <Card className={classes.widgetCard}>
      <CardContent>
        <Link to="/procedimentos" className={classes.link}>
          <Typography variant="h5" className={classes.widgetTitle}>
            Procedimentos Realizados
          </Typography>
        </Link>
        <Typography className={classes.body1}>
          Hoje: {dailyProcedures}
        </Typography>
        <Typography className={classes.body1}>
          Esta Semana: {weeklyProcedures}
        </Typography>
        <Typography className={classes.body1}>
          Este Mês: {monthlyProcedures}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ProceduresWidget;
