import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField } from '@material-ui/core'; 
import { useNavigate } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/ListFinanceiro.css';

const CalculateEarnings = () => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
  const [periodEarnings, setPeriodEarnings] = useState(null);
  const [realTimeEarnings, setRealTimeEarnings] = useState({ daily: 0, weekly: 0, monthly: 0 });
  const [error, setError] = useState('');

  useEffect(() => {
    const updateEarnings = async () => {
      try {
        const response = await axios.get('/api/current-earnings');
        if (response.data) {
          setRealTimeEarnings(response.data);
        }
      } catch (err) {
        setError('Erro ao atualizar os ganhos: ' + err.message);
      }
    };

    const interval = setInterval(updateEarnings, 600000); // Atualização a cada 10 minutos
    return () => clearInterval(interval);
  }, []);

  const calculateEarnings = async (period) => {
    try {
      const response = await axios.post('/api/calculate-earnings', {
        startDate,
        endDate,
        period
      });

      if (response.data) {
        setPeriodEarnings(response.data.totalEarnings);
      }
    } catch (err) {
      setError('Erro ao calcular os ganhos: ' + err.message);
    }
  };

  return (
    <div className="earnings-calculator">
        <button onClick={() => navigate('/')} className="btn-voltar">Voltar</button>
      <h1>Resumo financeiro</h1>

      <div className="real-time-earnings">
        <h2>Faturamento</h2>
        <h3>Diário: R$ {realTimeEarnings.daily.toFixed(2)}</h3>
        <h3>Semanal: R$ {realTimeEarnings.weekly.toFixed(2)}</h3>
        <h3>Mensal: R$ {realTimeEarnings.monthly.toFixed(2)}</h3>
      </div>

      <div className="period-earnings">
        <h2>Faturamento por Período</h2>
        <div>
          <TextField
            label="Data Inicial"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <TextField
            label="Data Final"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
          <button onClick={() => calculateEarnings('custom')}>Calcular Período</button>
        </div>
        <div className="buttons-container">
          <button onClick={() => calculateEarnings('daily')}>Diário</button>
          <button onClick={() => calculateEarnings('weekly')}>Semanal</button>
          <button onClick={() => calculateEarnings('monthly')}>Mensal</button>
        </div>
        {periodEarnings && <h2>Faturamento: R$ {periodEarnings}</h2>}
      </div>

      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default CalculateEarnings;


