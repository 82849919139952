import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/PatientList.css';
import api from '../api';
import { useNavigate } from 'react-router-dom';
import Pagination from './Pagination';
import PatientSearch from './PatientSearch';

const PatientsList = () => {
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [patients, setPatients] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const reportsPerPage = 10;
  const [openMenuId, setOpenMenuId] = useState(null);
  const [sortKey, setSortKey] = useState('nomePaciente'); 
  const [sortOrder, setSortOrder] = useState('asc');
  const [isSortMenuOpen, setIsSortMenuOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchPatients = async () => {
      try {
        const response = await api.get('/api/radiografias');
        setPatients(response.data);
        setFilteredPatients(response.data);
      } catch (error) {
        console.error('Erro ao buscar pacientes:', error);
      }
    };
    fetchPatients();
  }, []);


  const handleSortChange = (key) => {
    if (sortKey === key && sortOrder === 'asc') {
      setSortOrder('desc');
    } else {
      setSortOrder('asc');
    }
    setSortKey(key);
  };

  useEffect(() => {
    // Quando sortKey ou sortOrder mudar, reordenar os pacientes
    const sortedPatients = [...patients].sort((a, b) => {
      if (sortKey === 'nomePaciente') {
        return sortOrder === 'asc'
          ? a.nomePaciente.localeCompare(b.nomePaciente)
          : b.nomePaciente.localeCompare(a.nomePaciente);
      } else { // 'createdAt' ou 'updatedAt'
        return sortOrder === 'asc'
          ? new Date(a[sortKey]) - new Date(b[sortKey])
          : new Date(b[sortKey]) - new Date(a[sortKey]);
      }
    });

    setFilteredPatients(sortedPatients);
  }, [patients, sortKey, sortOrder]);

    // Função para alternar o menu
    const toggleMenu = (id) => {
      if (openMenuId === id) {
        setOpenMenuId(null);
      } else {
        setOpenMenuId(id);
      }
    };

    const toggleSortMenu = () => {
      setIsSortMenuOpen(!isSortMenuOpen);
    };  

  const deleteRadiografia = async (id) => {
    try {
      await api.delete(`/api/radiografias/${id}`);
      setFilteredPatients(filteredPatients.filter(patient => patient.id !== id));
      // Atualize também a lista de pacientes, caso necessário
      setPatients(patients.filter(patient => patient.id !== id));
    } catch (error) {
      console.error('Erro ao excluir radiografia:', error);
    }
  };

  const handleSearchResult = (searchTerm) => {
    if (!searchTerm) {
      setFilteredPatients(patients);
    } else {
      const lowercasedTerm = searchTerm.toLowerCase();
      const result = patients.filter((patient) =>
        patient.nomePaciente.toLowerCase().includes(lowercasedTerm)
      );
      setFilteredPatients(result);
    }
  };

  const getCurrentPatients = () => {
    const indexOfLastPatient = currentPage * reportsPerPage;
    const indexOfFirstPatient = indexOfLastPatient - reportsPerPage;
    return filteredPatients.slice(indexOfFirstPatient, indexOfLastPatient);
  };

  return (
    <div className="patients-list-container">
      <button onClick={() => navigate('/')} className="btn-voltar">Voltar</button>
      <button onClick={toggleSortMenu} className="btn-sort">⋮</button> {/* Botão de três pontos */}

      {isSortMenuOpen && (
        <div className="sorting-options">
          <button onClick={() => handleSortChange('nomePaciente')} className="btn-sort">
            Ordenar por Nome {sortKey === 'nomePaciente' && (sortOrder === 'asc' ? '↓' : '↑')}
          </button>
          <button onClick={() => handleSortChange('createdAt')} className="btn-sort">
            Ordenar por Data de Criação {sortKey === 'createdAt' && (sortOrder === 'asc' ? '↓' : '↑')}
          </button>
          <button onClick={() => handleSortChange('updatedAt')} className="btn-sort">
            Ordenar por Última Atualização {sortKey === 'updatedAt' && (sortOrder === 'asc' ? '↓' : '↑')}
          </button>
        </div>
      )}
      <div className="patients-list">
        <h2>Radiografias de pacientes</h2>
        <PatientSearch onSearchResult={handleSearchResult} />
        <button onClick={() => navigate('/radiografias/nova')} className="btn-tw">Nova radiografia</button>
        <ul>
        {getCurrentPatients().map((patient) => (
          <li key={patient.id}>
            <Link to={`/radiografias/${patient.id}`}>{patient.nomePaciente}</Link>
            {/* Botão de três pontos */}
            <button onClick={() => toggleMenu(patient.id)} className="btn-t2">⋮</button>
            {/* Opção de excluir aparece quando o menu está aberto */}
            {openMenuId === patient.id && (
              <button onClick={() => deleteRadiografia(patient.id)}>Excluir</button>
            )}
          </li>
          ))}
        </ul>
        <Pagination
          reportsPerPage={reportsPerPage}
          totalPatients={patients.length}
          paginate={setCurrentPage}
          currentPage={currentPage}
        />
      </div>
    </div>
  );  
};

export default PatientsList;
