import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Dashboard from './pages/Dashboard';
import Agenda from './pages/Agenda';
import AddEditConsulta from './pages/AddEditConsulta';
import CalculateEarnings from './pages/CalculateEarnings';
import RegisterTransaction from './pages/RegisterTransaction';
import RelatorioProcedimentos from './pages/Procedimentos';
import AddProtese from './components/AddProtese';
import ListProteses from './components/LIstProteses';
import MedicalCertificate from './components/MedicalCertificate';
import PatientRadiographs from './components/PatientRadiographs';
import PatientDetailPage from './components/PatientDetailPage';
import PatientsList from './components/PatientsList';
import PaymentList from './components/PaymentList';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/agenda" element={<Agenda />} />
        <Route path="/consultas/add" element={<AddEditConsulta />} />
        <Route path="/consultas/edit/:id" element={<AddEditConsulta />} />
        <Route path="/protese" element={<AddProtese />} />
        <Route path="/proteses" element={<ListProteses />} />
        <Route path="/financeiro" element={<CalculateEarnings />} />
        <Route path="/financeiro/add" element={<RegisterTransaction />} />
        <Route path="/procedimentos" element={<RelatorioProcedimentos />} />
        <Route path="/medical" element={<MedicalCertificate />} />
        <Route path="/radiografias/nova" element={<PatientRadiographs />} />
        <Route path="/radiografias/:patientId" element={<PatientDetailPage />} />
        <Route path="/pacientes" element={<PatientsList />} />
        <Route path="/payment" element={<PaymentList />} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;
