// pages/Proteses/AddEditProtese.js
import React, { useState } from 'react';
import api from '../api';
import { useNavigate } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/AddEditProtese.css';

const AddProtese = ({ id }) => {
  const [formData, setFormData] = useState({
    nomePaciente: '',
    tipoProtese: '',
    dataMoldagem: '',
    dataEnvio: '',
    dataRetorno: '',
    novaMoldagem: '',
    dataEntrega: '',
  });
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
  
    try {
      await api.post('/api/proteses', formData);
      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 3000);
      // Redirecionamento e outros tratamentos
    } catch (error) {
      console.error('Erro ao adicionar trabalho de prótese:', error);
      // Aqui você pode optar por mostrar uma mensagem de erro, se desejar
    } finally {
      setIsLoading(false); // Encerra o carregamento independentemente do resultado
    }
  };

    return (
    <div className="add-edit-protese-container">
      <button onClick={() => navigate('/')} className="btn-voltar">Voltar</button>
        <h1>{id ? 'Editar Trabalho de Prótese' : 'Novo Trabalho de Prótese'}</h1>
        <form onSubmit={handleSubmit} className="add-edit-protese-form">
        <div>
            <label>Nome do Paciente:</label>
            <input
            type="text"
            name="nomePaciente"
            value={formData.nomePaciente}
            onChange={handleInputChange}
            required
            />
        </div>
        <div>
            <label>Tipo de Prótese:</label>
            <input
            type="text"
            name="tipoProtese"
            value={formData.tipoProtese}
            onChange={handleInputChange}
            required
            />
        </div>
        <div>
            <label>Data da Moldagem:</label>
            <input
            type="date"
            name="dataMoldagem"
            value={formData.dataMoldagem}
            onChange={handleDateChange}
          />
        </div>
        <div>
            <label>Data de Envio:</label>
            <input
            type="date"
            name="dataEnvio"
            value={formData.dataEnvio}
            onChange={handleDateChange}
          />
        </div>
        <div>
            <label>Data de Retorno:</label>
            <input
            type="date"
            name="dataRetorno"
            value={formData.dataRetorno}
            onChange={handleDateChange}
          />
        </div>
        <div>
            <label>Nova Moldagem (se necessário):</label>
            <input
            type="date"
            name="novaMoldagem"
            value={formData.novaMoldagem}
            onChange={handleDateChange}
          />
        </div>
        <div>
            <label>Data da Entrega:</label>
            <input
            type="date"
            name="dataEntrega"
            value={formData.dataEntrega}
            onChange={handleDateChange}
          />
        </div>
        <button type="submit" disabled={isLoading}>
            {isLoading ? (
                <>
                    <div className="spinner"></div><span>Carregando...</span>
                </>
            ) : "Adicionar radiografia"}
        </button>
        {showNotification && <div className="notification">Trabalho de prótese criada com sucesso!</div>}
        </form>
    </div>
   );
};

export default AddProtese;
