import React, { useState, useEffect } from 'react';
import api from '../api';
import { useNavigate } from 'react-router-dom';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ptBR from 'date-fns/locale/pt-BR';
import '../styles/ListProteses.css';
import Pagination from '../components/Pagination';
import ProteseSearch from '../components/ProteseSearch';

registerLocale('pt-BR', ptBR);

const ListProteses = () => {
  const navigate = useNavigate();
  const [proteses, setProteses] = useState([]);
  const [editingProtese, setEditingProtese] = useState(null);
  const [searchName, setSearchName] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [protesesPerPage] = useState(10);

  useEffect(() => {
    const fetchProteses = async () => {
      try {
        const response = await api.get('/api/proteses');
        setProteses(response.data);
      } catch (error) {
        console.error('Erro ao buscar registros de próteses:', error);
      }
    };

    fetchProteses();
  }, []);

  const startEditing = (protese) => {
    setEditingProtese({ ...protese });
  };

  const handleSearch = (searchTerm) => {
    setSearchName(searchTerm.toLowerCase());
  };

  const updateProtese = async (id, updatedData) => {
    try {
      await api.put(`/api/proteses/${id}`, updatedData);
      setProteses(proteses.map(protese =>
        protese.id === id ? { ...protese, ...updatedData } : protese
      ));
      setEditingProtese(null);
    } catch (error) {
      console.error('Erro ao atualizar o registro de prótese', error);
    }
  };

  const deleteProtese = async (id) => {
    try {
      await api.delete(`/api/proteses/${id}`);
      setProteses(proteses.filter(protese => protese.id !== id));
    } catch (error) {
      console.error('Erro ao excluir a prótese', error);
    }
  };

  const filteredProteses = proteses.filter(protese => {
    return protese.nomePaciente.toLowerCase().includes(searchName.toLowerCase()) &&
      (!startDate || new Date(protese.dataMoldagem).toLocaleDateString() === startDate.toLocaleDateString());
  });

  const indexOfLastProtese = currentPage * protesesPerPage;
  const indexOfFirstProtese = indexOfLastProtese - protesesPerPage;
  const currentProteses = filteredProteses.slice(indexOfFirstProtese, indexOfLastProtese);

  // Função auxiliar para formatar as datas
  const formatDate = (dateString) => {
    // Cria uma data no fuso horário local a partir de uma string ISO
    const date = new Date(dateString + 'T00:00:00');
    return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
  };
  
  return (
    <div className="list-proteses-container">
      <button onClick={() => navigate('/')} className="btn-voltar">Voltar</button>
      <h1>Registros de Próteses</h1>
      <ProteseSearch onSearch={handleSearch} />
      <button onClick={() => navigate('/protese')} className="btn-tw">Novo trabalho de prótese</button>
      <DatePicker
        selected={startDate}
        onChange={date => setStartDate(date)}
        dateFormat="dd/MM/yyyy"
        placeholderText="Escolha uma data"
        locale="pt-BR"
      />
      <table className="proteses-table">
        <thead>
          <tr>
            <th>Paciente</th>
            <th>Tipo de prótese</th>
            <th>Data da moldagem</th>
            <th>Data de envio</th>
            <th>Data de retorno</th>
            <th>Nova moldagem</th>
            <th>Data de entrega</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {currentProteses.map(protese => (
            <tr key={protese.id}>
              {editingProtese?.id === protese.id ? (
                <>
                  {/* Campos editáveis */}
                  <td><input type="text" value={editingProtese.nomePaciente} onChange={(e) => setEditingProtese(prev => ({ ...prev, nomePaciente: e.target.value }))} /></td>
                  <td><input type="text" value={editingProtese.tipoProtese} onChange={(e) => setEditingProtese(prev => ({ ...prev, tipoProtese: e.target.value }))} /></td>
                  <td><input type="date" value={editingProtese.dataMoldagem} onChange={(e) => setEditingProtese(prev => ({ ...prev, dataMoldagem: e.target.value }))} /></td>
                  <td><input type="date" value={editingProtese.dataEnvio} onChange={(e) => setEditingProtese(prev => ({ ...prev, dataEnvio: e.target.value }))} /></td>
                  <td><input type="date" value={editingProtese.dataRetorno} onChange={(e) => setEditingProtese(prev => ({ ...prev, dataRetorno: e.target.value }))} /></td>
                  <td><input type="date" value={editingProtese.novaMoldagem} onChange={(e) => setEditingProtese(prev => ({ ...prev, novaMoldagem: e.target.value }))} /></td>
                  <td><input type="date" value={editingProtese.dataEntrega} onChange={(e) => setEditingProtese(prev => ({ ...prev, dataEntrega: e.target.value }))} /></td>
                </>
              ) : (
                <>
                  {/* Dados normais */}
                  <td>{protese.nomePaciente}</td>
                  <td>{protese.tipoProtese}</td>
                  <td>{formatDate(protese.dataMoldagem)}</td>
                  <td>{formatDate(protese.dataEnvio)}</td>
                  <td>{formatDate(protese.dataRetorno)}</td>
                  <td>{formatDate(protese.novaMoldagem)}</td>
                  <td>{formatDate(protese.dataEntrega)}</td>
                  {/* Adicione outras células com dados aqui */}
                </>
              )}
            <td>
              {editingProtese?.id === protese.id ? (
                <>
                  <button onClick={() => updateProtese(editingProtese.id, editingProtese)} className="update-btn">Salvar</button>
                  <button onClick={() => setEditingProtese(null)} className="cancel-btn">Cancelar</button>
                </>
              ) : (
                <>
                  <button onClick={() => startEditing(protese)} className="edit-btn">Editar</button>
                  <button onClick={() => deleteProtese(protese.id)} className="delete-btn">Excluir</button>
                </>
              )}
            </td>
          </tr>
        ))}
      </tbody>
      </table>
      <Pagination
        reportsPerPage={protesesPerPage}
        totalReports={filteredProteses.length}
        paginate={setCurrentPage}
        currentPage={currentPage}
      />
    </div>
  );
};

export default ListProteses;