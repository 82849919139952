// src/components/ProteseSearch.js
import React, { useState } from 'react';

const ProteseSearch = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    onSearch(e.target.value); // Chama a função de busca passada como prop
  };

  return (
    <input
      type="text"
      placeholder="Buscar por nome do paciente..."
      value={searchTerm}
      onChange={handleSearch}
      className="search-box"
    />
  );
};

export default ProteseSearch;
