import React, { useState, useEffect } from 'react';
import api from '../api';

const PaymentList = () => {
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    fetchTransactions();
  }, []);

  const fetchTransactions = async () => {
    try {
      const response = await api.get('/api/transacoes');
      setTransactions(response.data);
    } catch (error) {
      console.error('Erro ao buscar transações:', error);
    }
  };

  const markAsPaid = async (transactionId, paymentDate) => {
    try {
      await api.put(`/api/transacoes/${transactionId}`, { paymentDate, isPending: false });
      fetchTransactions(); // Atualiza a lista após marcar como pago
    } catch (error) {
      console.error('Erro ao atualizar o status da transação:', error);
    }
  };

  return (
    <div>
      <h1>Lista de Pagamentos</h1>
      {transactions.map((transaction) => (
        <div key={transaction.id}>
          <p>Pagador: {transaction.payerName}</p>
          <p>Valor: R$ {transaction.amount}</p>
          <p>Status: {transaction.isPending ? 'Pendente' : 'Pago'}</p>
          {transaction.isPending && (
            <button onClick={() => markAsPaid(transaction.id, new Date().toISOString().split('T')[0])}>
              Marcar como Pago
            </button>
          )}
        </div>
      ))}
    </div>
  );
};

export default PaymentList;
