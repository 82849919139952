import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

// Função mock para simular chamada de API
const fetchFinancialData = () => {
  return Promise.resolve({
    daily: 1200.00, // Exemplo: R$ 1200,00 ganhos hoje
    weekly: 8400.00, // Exemplo: R$ 8400,00 ganhos nesta semana
    monthly: 33600.00 // Exemplo: R$ 33600,00 ganhos neste mês
  });
};

const FinanceWidget = ({ classes }) => {
  const [dailyEarnings, setDailyEarnings] = useState(0);
  const [weeklyEarnings, setWeeklyEarnings] = useState(0);
  const [monthlyEarnings, setMonthlyEarnings] = useState(0);
  const [showDaily, setShowDaily] = useState(false);
  const [showWeekly, setShowWeekly] = useState(false);
  const [showMonthly, setShowMonthly] = useState(false);

  const toggleVisibility = (type) => {
    if (type === 'daily') setShowDaily(!showDaily);
    if (type === 'weekly') setShowWeekly(!showWeekly);
    if (type === 'monthly') setShowMonthly(!showMonthly);
  };  

  useEffect(() => {
    fetchFinancialData().then(data => {
      setDailyEarnings(data.daily);
      setWeeklyEarnings(data.weekly);
      setMonthlyEarnings(data.monthly);
    });
  }, []);

  return (
    <Card className={classes.widgetCard}>
      <CardContent>
        <Link to="/financeiro" className={classes.link}>
          <Typography variant="h5" className={classes.widgetTitle}>
            Resumo Financeiro
          </Typography>
        </Link>
    <Typography className={classes.body1} onClick={() => toggleVisibility('daily')}>
      Hoje: {showDaily ? `R$ ${dailyEarnings.toFixed(2)}` : '●●●●●'}
    </Typography>
    <Typography className={classes.body1} onClick={() => toggleVisibility('weekly')}>
      Esta Semana: {showWeekly ? `R$ ${weeklyEarnings.toFixed(2)}` : '●●●●●'}
    </Typography>
    <Typography className={classes.body1} onClick={() => toggleVisibility('monthly')}>
      Este Mês: {showMonthly ? `R$ ${monthlyEarnings.toFixed(2)}` : '●●●●●'}
    </Typography>
      </CardContent>
    </Card>
  );
};

export default FinanceWidget;
