import React, { useState } from 'react';

const PatientSearch = ({ onSearchResult }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    onSearchResult(term); // Apenas passa o termo de pesquisa
  };

  return (
    <input
      type="text"
      placeholder="Buscar por nome"
      value={searchTerm}
      onChange={handleSearch}
      className="search-box"
    />
  );
};

export default PatientSearch;
