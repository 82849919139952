import React, { useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTooth } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import '../styles/MedicalCertificate.css';
import { ptBR } from 'date-fns/locale';
import { format } from 'date-fns';

registerLocale('pt-BR', ptBR);

const MedicalCertificate = () => {
  const [certificateData, setCertificateData] = useState({
    patientName: '',
    patientRG: '',
    treatmentDuration: new Date(),
    restPeriod: '',
    procedimento: '',
    date: new Date(),
  });

  const navigate = useNavigate();

  const [isCertificateCreated, setIsCertificateCreated] = useState(false);

  const createCertificate = () => {
    setIsCertificateCreated(true);
  };

  const printCertificate = () => {
    window.print();
  };

  const handleRestPeriodChange = (e) => {
    const restPeriodWithUnit = e.target.value ? `${e.target.value} Dias` : '';
    setCertificateData({ ...certificateData, restPeriod: restPeriodWithUnit });
  };

  const handleInputChange = (e) => {
    setCertificateData({ ...certificateData, [e.target.name]: e.target.value });
  };

  const handleDateChange = (date) => {
    setCertificateData({ ...certificateData, date });
  };

  return (
    <div className="certificate-container">
      <div className="certificate">
      <button onClick={() => navigate('/')} className="btn-voltar">Voltar</button>
      {!isCertificateCreated ? (
        <form className="certificate-form">
          <div>
            <label>Atesto para devidos fins, que o(a) paciente:</label>
            <input
              type="text"
              name="patientName"
              value={certificateData.patientName}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
          <label>Compareceu a este Consultório Odontológico para tratamento no horário:</label>
          <DatePicker
            selected={certificateData.treatmentDuration}
            onChange={date => setCertificateData({ ...certificateData, treatmentDuration: date })}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15} // Intervalos de 15 minutos
            timeCaption="Horário"
            dateFormat="HH:mm" // Formato 24 horas
            locale="pt-BR"
          />
        </div>
        <div>
            <label>Devendo permanecer em repouso por:</label>
            <input
                type="number"
                name="restPeriod"
                value={certificateData.restPeriod.replace(/\D+/g, '')} // Isto remove não dígitos ao exibir o valor.
                onChange={handleRestPeriodChange}
                min="1" // Mínimo de um dia
                required
            />
            <span> Dias</span> {/* A palavra "Dias" é exibida diretamente após o input */}
            </div>
          <div>
            <label>Procedimento realizado:</label>
            <input
              type="text"
              name="procedimento"
              value={certificateData.procedimento}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <label>Senhor Do Bonfim-BA</label>
            <DatePicker
              selected={certificateData.date}
              onChange={handleDateChange}
              dateFormat="dd/MM/yyyy"
              locale="pt-BR"
            />
          </div>
          <button type="button" onClick={createCertificate} className="create-button">
            Gerar atestado
          </button>
        </form>
                ) : (
                    <div className="certificate-preview">
                <FontAwesomeIcon icon={faTooth} className="tooth-icon" />
                <div>
                    <h2>Ortodontia - Consultório Dr. Jorge Simões</h2>
                    <h3>Dr. Jorge Simões - CRO/BA 2289</h3>
                </div>
                <p>Atesto para devidos fins, que o(a) paciente: {certificateData.patientName}</p>
                <p>Compareceu a este Consultório Odontológico para tratamento no horário: {format(certificateData.treatmentDuration, 'HH:mm')}</p>
                <p>Devendo permanecer em repouso por: {certificateData.restPeriod}</p>
                <p>Procedimento realizado: {certificateData.procedimento}</p>
                <p>Senhor Do Bonfim-BA, {format(certificateData.date, "dd 'de' MMMM 'de' yyyy", { locale: ptBR })}</p>
                <div className="signature-section">
                <div className="signature-line">
                    <hr />
                    <p>Declaro ter recebido cópia de igual conteúdo.</p>
                    <p>Ass. do paciente ou de seu representante legal</p>
                </div>
                <div className="signature-line">
                    <hr />
                    <p>Carimbo e assinatura</p>
                </div>
                </div>

                <button onClick={printCertificate} className="print-button">Imprimir Atestado</button>
            </div>
            )}
         </div>
      </div>
  );
};

export default MedicalCertificate;