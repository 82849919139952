import React, { useState } from 'react';
import {
  Drawer, makeStyles, useTheme, useMediaQuery,
  CssBaseline, Hidden, SwipeableDrawer, Grid, Toolbar, Typography, IconButton, List,
  ListItem, ListItemIcon, ListItemText, AppBar // Importações adicionadas
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTooth } from '@fortawesome/free-solid-svg-icons';
import EventNoteIcon from '@material-ui/icons/EventNote';
import AddBoxIcon from '@material-ui/icons/AddBox';
import HealingIcon from '@material-ui/icons/Healing';
import ListAltIcon from '@material-ui/icons/ListAlt';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ReceiptIcon from '@material-ui/icons/Receipt';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import DescriptionIcon from '@material-ui/icons/Description';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import AgendaWidget from '../components/AgendaWidget';
import ProceduresWidget from '../components/ProceduresWidget';
import FinanceWidget from '../components/FinanceWidget';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    color: '#edf2f4',
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 'bold',
    fontSize: '1.6rem'
    // Adicione outros estilos conforme necessário
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  listItem: {
    '&:hover': {
      backgroundColor: '#f5f5f5', // Cor de fundo ao passar o mouse
      boxShadow: '0px 4px 12px rgba(0,0,0,0.3)', // Aumentando a intensidade e o spread da sombra
    },
    '&.Mui-selected': {
      backgroundColor: '#eceff1', // Cor de fundo quando selecionado
      '&:hover': {
        backgroundColor: '#e1f5fe', // Cor de fundo ao passar o mouse quando selecionado
        boxShadow: '0px 4px 12px rgba(0,0,0,0.3)', // Mantendo a sombra ao passar o mouse
      },
    },
    fontFamily: "'Poppins', sans-serif", 
    fontWeight: 600,
  },
  listItemIcon: {
    color: theme.palette.primary.main, // Cor dos ícones
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  link: {
    color: 'inherit', // herda a cor do elemento pai
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  drawerContainer: {
    overflow: 'auto',
  },
  hide: {
    display: 'none',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  listItemText: {
    fontSize: '1.1rem',
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 500,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessário para o conteúdo estar abaixo da AppBar
    ...theme.mixins.toolbar,
    minHeight: '65px', // Ajuste a altura conforme necessário
},
  // Estilos para os widgets
  widgetCard: {
    marginBottom: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#ececec', // ou um valor personalizado
    boxShadow: theme.shadows[3], // escolha um nível de sombra da paleta de sombras
  },
  widgetTitle: {
    fontSize: '1.25rem',
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 600,
    color: '#46505e',
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  widgetContent: {
    marginTop: theme.spacing(1),
    fontSize: '1rem',
    color: theme.palette.text.secondary,
  },
  // Estilos para o Typography body1
  body1: {
    fontSize: '1.15rem',
    fontFamily: "'Poppins', ui-sans-serif",
    fontWeight: 600,
    margin: '8px',
    lineHeight: 1.6,
    letterSpacing: '0.00938em',
  },
  body2: {
    color: 'green',
    fontSize: '1.15rem',
    fontFamily: "'Poppins', ui-sans-serif",
    fontWeight: 600,
    lineHeight: 1.6,
    letterSpacing: '0.00938em',
  },
  body3: {
    color: 'red',
    fontSize: '1.15rem',
    fontFamily: "'Poppins', ui-sans-serif",
    fontWeight: 600,
    lineHeight: 1.6,
    letterSpacing: '0.00938em',
  },
  customHr: {
    border: 0,
    height: '1px',
    backgroundImage: 'linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1))',
    margin: theme.spacing(2, 0),
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [currentAppointment, setCurrentAppointment] = useState({/* dados iniciais ou null */});
  const [nextAppointment, setNextAppointment] = useState({/* dados iniciais ou null */});

  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const drawerContent = (
    <div>
      <div className={classes.body1} />
      <List>
      <ListItem button component={Link} to="/agenda" className={classes.listItem}>
            <ListItemIcon className={classes.listItemIcon}><EventNoteIcon /></ListItemIcon>
            <ListItemText primary="Agendamentos" />
          </ListItem>
          <ListItem button component={Link} to="/consultas/add">
            <ListItemIcon className={classes.listItemIcon}><AddBoxIcon /></ListItemIcon>
            <ListItemText primary="Nova Consulta" className={classes.listItemText}/>
          </ListItem>
          <ListItem button component={Link} to="/pacientes" className={classes.listItem}>
          <ListItemIcon className={classes.listItemIcon}>
            <PhotoCameraIcon />
          </ListItemIcon>
          <ListItemText primary="Radiografias" />
        </ListItem>
          <ListItem button component={Link} to="/protese">
            <ListItemIcon className={classes.listItemIcon}><HealingIcon /></ListItemIcon>
            <ListItemText primary="Adicionar trabalho de Prótese" />
          </ListItem>
          <ListItem button component={Link} to="/proteses">
            <ListItemIcon className={classes.listItemIcon}><ListAltIcon /></ListItemIcon>
            <ListItemText primary="Registros de Próteses" />
          </ListItem>
          <ListItem button component={Link} to="/financeiro/add">
            <ListItemIcon className={classes.listItemIcon}><ReceiptIcon /></ListItemIcon>
            <ListItemText primary="Registrar Transação" />
          </ListItem>
          <ListItem button component={Link} to="/medical">
            <ListItemIcon className={classes.listItemIcon}><NoteAddIcon /></ListItemIcon>
            <ListItemText primary="Gerar Atestado" />
          </ListItem>
          <ListItem button component={Link} to="/procedimentos">
            <ListItemIcon className={classes.listItemIcon}><DescriptionIcon /></ListItemIcon>
            <ListItemText primary="Relatório de Procedimentos" />
          </ListItem>
          <ListItem button component={Link} to="/financeiro">
            <ListItemIcon className={classes.listItemIcon}><MonetizationOnIcon /></ListItemIcon>
            <ListItemText primary="Resumo Financeiro" />
          </ListItem>
      </List>
    </div>
  );  

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} style={{ height: '65px' }}>
        <Toolbar>
        <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <FontAwesomeIcon icon={faTooth} className="tooth-icon" style={{ marginRight: '15px', color: '#edf2f4', fontSize: '2.4rem' }} />
          <Typography variant="h6" component="div" className={classes.title}>
            Consultório
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
        <SwipeableDrawer
        variant="temporary"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        onOpen={handleDrawerToggle}
        classes={{ paper: classes.drawerPaper }}
        ModalProps={{ keepMounted: true }}
      >
        {drawerContent}
      </SwipeableDrawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            // Removido o atributo open, pois não é necessário no Drawer permanente
          >
          {drawerContent}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
        <AgendaWidget 
          classes={classes} 
          currentAppointment={currentAppointment} 
          nextAppointment={nextAppointment} 
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
          <ProceduresWidget classes={classes} />
        </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FinanceWidget classes={classes} />
          </Grid>
        </Grid>
        {/* Outros componentes ou conteúdo da página */}
      </main>
    </div>
  );
};

export default Dashboard;
