import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import api from '../api';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTooth } from '@fortawesome/free-solid-svg-icons';
import '../styles/ListFinanceiro.css';

const formatDateToInput = (date) => {
  let day = ('0' + date.getDate()).slice(-2);
  let month = ('0' + (date.getMonth() + 1)).slice(-2);
  let year = date.getFullYear();
  return `${year}-${month}-${day}`;
};

const RegisterTransaction = () => {
  const navigate = useNavigate();
  const [transactionData, setTransactionData] = useState({
    payerName: '',
    serviceDate: formatDateToInput(new Date()), // Data do serviço
    amount: '',
    service: ''
  });
  const [isPending, setIsPending] = useState(true); // Novo estado para gerenciar se a transação é pendente
  const [receiptData, setReceiptData] = useState(null);
  const [message, setMessage] = useState('');
  const [responseStatus, setResponseStatus] = useState(null);
  const [displayedValue, setDisplayedValue] = useState('');

  const printReceipt = () => {
    window.print();
  };

  function formatCurrencyInput(str) {
    let cleaned = ('' + str).replace(/\D/g, '');
    let amount = parseInt(cleaned, 10) || 0;
    return (amount / 100).toFixed(2).replace('.', ',');
  }

  const handleValueChange = (e) => {
    const newValue = e.target.value.replace(',', '.');
    setDisplayedValue(formatCurrencyInput(newValue));
    setTransactionData({ ...transactionData, amount: newValue });
  };

  const handleInputChange = (e) => {
    setTransactionData({ ...transactionData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dataToSubmit = {
      ...transactionData,
      isPending,
      paymentDate: isPending ? null : formatDateToInput(new Date()) // Define a data de pagamento se não estiver pendente
    };

    try {
      const response = await api.post('/api/transacoes', dataToSubmit);
      const responseData = {
        ...response.data,
        receiverName: "Dr. Jorge Simões CRO/BA 2289",
        location: "Consultório Dr. Jorge Simões",
        paymentDate: dataToSubmit.paymentDate ? dataToSubmit.paymentDate : 'Pendente'
      };
      setReceiptData(responseData);        
      setMessage('Transação registrada com sucesso.');
      setResponseStatus(200);
    } catch (error) {
      setResponseStatus('error');
      setMessage('Erro ao registrar transação: ' + error.message);
    }
  };

  return (
    <div className="transaction-container">
      <button onClick={() => navigate('/')} className="btn-voltar">Voltar</button>
      <Link to="/payment" className="nav-link">
            Pagamentos pendentes
              </Link>
      <h1>Registrar Transação</h1>
      {message && (
        <p className={`message ${responseStatus === 200 ? 'message-success' : 'message-error'}`}>
          {message}
        </p>
      )}
      <form onSubmit={handleSubmit} className="transaction-form">
        <div>
          <label>Nome do Pagador:</label>
          <input
            type="text"
            name="payerName"
            value={transactionData.payerName}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <label>Data do Serviço:</label>
          <input
            type="date"
            name="serviceDate"
            value={transactionData.serviceDate}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <label>Serviço:</label>
          <input
            type="text"
            name="service"
            value={transactionData.service}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <label>Valor (R$):</label>
          <input
            type="text"
            name="amount"
            value={displayedValue}
            onChange={handleValueChange}
            required
          />
        </div>
        <div>
          <label>Registro Pendente:</label>
          <input
            type="checkbox"
            checked={isPending}
            onChange={() => setIsPending(!isPending)}
          />
        </div>
        <button type="submit">Registrar</button>
      </form>

      {receiptData && (
        <>
          <div className="receipt">
            <div className="receipt-header">
              <FontAwesomeIcon icon={faTooth} className="tooth-icon" />
              <div>
                <h2>Recibo de Pagamento</h2>
                <h3>Consultório Dr. Jorge Simões</h3>
              </div>
            </div>
            <div className="cro-info">Ortodontia - CRO/BA 2289</div>
            <p>Pagador: {receiptData.payerName}</p>
            <p>Recebedor: {receiptData.receiverName}</p>
            <p>Serviço: {receiptData.service}</p>
            <p>Valor: R$ {receiptData.amount}</p>
            <p>Endereço: {receiptData.location}</p>
            <p>Data do Serviço: {receiptData.serviceDate}</p>
            <p>Status de Pagamento: {receiptData.paymentDate}</p>
          </div>
          <button onClick={printReceipt} className="print-button">Imprimir</button>
        </>
      )}
    </div>
  );
};

export default RegisterTransaction;
