import React, { useState } from 'react';
import '../styles/PatientRadiographs.css';
import api from '../api';
import { useNavigate } from 'react-router-dom';

const PatientRadiographs = () => {
    const [formData, setFormData] = useState({
        nomePaciente: '',
        observacoes: '',
    });
    const [selectedFiles, setSelectedFiles] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [showNotification, setShowNotification] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFileChange = (e) => {
        setSelectedFiles(Array.from(e.target.files));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Inicia o carregamento
    
        const submissionData = new FormData();
        submissionData.append('nomePaciente', formData.nomePaciente);
        submissionData.append('observacoes', formData.observacoes);
        selectedFiles.forEach(file => {
            submissionData.append('radiographs', file);
        });
    
        try {
            const response = await api.post('/api/radiografias', submissionData);
            console.log('Paciente adicionado:', response.data);
            setShowNotification(true);
            setTimeout(() => setShowNotification(false), 3000); // Esconde a notificação após 3 segundos
            setSelectedFiles([]);
            setFormData({ nomePaciente: '', observacoes: '' }); // Limpar o formulário
        } catch (error) {
            console.error('Erro ao adicionar paciente:', error);
            // Aqui você pode optar por mostrar uma mensagem de erro, se desejar
        } finally {
            setIsLoading(false); // Encerra o carregamento independentemente do resultado
        }
    };    

    return (
        <div className="patient-radiographs-container">
            <button onClick={() => navigate('/pacientes')} className="btn-voltar">Voltar</button>
            <h2>Nova Radiografia</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Nome do Paciente:</label>
                    <input
                        type="text"
                        name="nomePaciente"
                        value={formData.nomePaciente}
                        onChange={handleInputChange}
                    />
                </div>
                <div>
                    <label>Observações:</label>
                    <input 
                        type="text"
                        name="observacoes"
                        value={formData.observacoes}
                        onChange={handleInputChange}
                    />
                </div>
                <div>
                    <label>Radiografias:</label>
                    <input 
                        type="file" 
                        multiple 
                        onChange={handleFileChange} 
                    />
                </div>
                <button type="submit" disabled={isLoading}>
            {isLoading ? (
                <>
                    <div className="spinner"></div><span>Carregando...</span>
                </>
            ) : "Adicionar radiografia"}
        </button>
        {showNotification && <div className="notification">Radiografia adicionada com sucesso!</div>}
            </form>
        </div>
    );    
};

export default PatientRadiographs;
