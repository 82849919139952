// pages/Consultas/Agenda.js
import React, { useState, useEffect } from 'react';
import api from '../api';
import { useNavigate } from 'react-router-dom';
import '../styles/Agenda.css';

const Agenda = () => {
    const navigate = useNavigate();
    const [consultas, setConsultas] = useState([]);
    const [consultasFiltrados, setConsultasFiltrados] = useState([]);
    const [dataPesquisa, setDataPesquisa] = useState("");

    // Utilitário para verificar se uma data está dentro da semana atual
    const isThisWeek = (date) => {
        const now = new Date();
        const firstDayOfWeek = now.getDate() - now.getDay(); // Primeiro dia da semana
        const lastDayOfWeek = firstDayOfWeek + 6; // Último dia da semana
        const firstDateOfWeek = new Date(now.setDate(firstDayOfWeek));
        firstDateOfWeek.setHours(0, 0, 0, 0);
        const lastDateOfWeek = new Date(now.setDate(lastDayOfWeek));
        lastDateOfWeek.setHours(23, 59, 59, 999);

        return date >= firstDateOfWeek && date <= lastDateOfWeek;
    };

    // Utilitário para verificar se uma data está na próxima semana
    const isNextWeek = (date) => {
        const now = new Date();
        const nextWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 7);
        const firstDayNextWeek = nextWeek.getDate() - nextWeek.getDay();
        const lastDayNextWeek = firstDayNextWeek + 6;
        const firstDateNextWeek = new Date(nextWeek.setDate(firstDayNextWeek));
        firstDateNextWeek.setHours(0, 0, 0, 0);
        const lastDateNextWeek = new Date(nextWeek.setDate(lastDayNextWeek));
        lastDateNextWeek.setHours(23, 59, 59, 999);

        return date >= firstDateNextWeek && date <= lastDateNextWeek;
    };

    // Adicione estas funções no seu componente Agenda
    const filterToday = () => {
        const today = new Date().toISOString().slice(0, 10);
        setConsultasFiltrados(consultas.filter(consulta => {
            const dataConsulta = new Date(consulta.dataHora).toISOString().slice(0, 10);
            return dataConsulta === today;
        }));
    };

    const filterThisWeek = () => {
        setConsultasFiltrados(consultas.filter(consulta => {
            const dataConsulta = new Date(consulta.dataHora);
            return isThisWeek(dataConsulta);
        }));
    };

    const filterNextWeek = () => {
        setConsultasFiltrados(consultas.filter(consulta => {
            const dataConsulta = new Date(consulta.dataHora);
            return isNextWeek(dataConsulta);
        }));
    };

    // Carrega as consultas do backend
    useEffect(() => {
        carregarConsultas();
    }, []);

    const carregarConsultas = () => {
        api.get('/api/consultas')
          .then(response => {
            setConsultas(response.data.filter(consulta => consulta.status === 'pendente'));
          })
          .catch(error => console.error('Erro ao buscar consultas', error));
    };

    const updateConsultaStatus = (consultaId, newStatus) => {
        api.put(`/api/consultas/${consultaId}`, { status: newStatus })
          .then(() => {
            carregarConsultas(); // Recarrega as consultas após a atualização
          })
          .catch(error => console.error('Erro ao atualizar consulta', error));
    };


    const filtrarPorData = () => {
        const filtrados = consultas.filter(consulta => {
            const dataConsulta = new Date(consulta.dataHora).toISOString().slice(0, 10);
            return dataConsulta === dataPesquisa;
        });
        setConsultasFiltrados(filtrados);
    };

    // Você pode adicionar aqui as funções filterToday, filterThisWeek e filterNextWeek
    // usando a mesma lógica do exemplo ServiceViewList.js fornecido anteriormente.

    let ultimaDataExibida = "";

    return (
        <div className="agenda-container">
             <button onClick={() => navigate('/')} className="btn-voltar">Voltar</button>

            <h3>Agenda de Atendimentos</h3>
            <button onClick={() => navigate('/consultas/add')} className="btn-tw">Agendar nova consulta</button>
            <div className="filtro-botoes">
                {/* Botões para filtrar por Hoje, Esta Semana e Próxima Semana */}
                <input
                    type="date"
                    value={dataPesquisa} 
                    onChange={(e) => setDataPesquisa(e.target.value)}
                />
                <button onClick={filtrarPorData}>Buscar</button>
            </div>
            <div className="filtro-botoes">
            <button onClick={filterToday}>Hoje</button>
            <button onClick={filterThisWeek}>Esta Semana</button>
            <button onClick={filterNextWeek}>Próxima Semana</button>
            {/* Restante do seu JSX */}
        </div>
            {/* Listagem de atendimentos */}
            {consultasFiltrados.map(consulta => {
                const dataConsulta = new Date(consulta.dataHora).toISOString().slice(0, 10);
                const mostrarDia = ultimaDataExibida !== dataConsulta;
                if (mostrarDia) {
                    ultimaDataExibida = dataConsulta;
                }
                return (
                    <React.Fragment key={consulta.id}>
                        {mostrarDia && <div className="dia-atendimento">{getDayOfWeek(consulta.dataHora)}</div>}
                        <div className="item-atendimento">
                            <span>Cliente: {consulta.nomePaciente}</span>
                            <span>Serviço: {consulta.service}</span>
                            <span>Data e horário: {formatDateAndTime(consulta.dataHora)}</span>
                            <span>Observações: {consulta.observacoes || "Sem observações"}</span>
                            <div className="item-atendimento-actions">
                            <button onClick={() => updateConsultaStatus(consulta.id, 'concluído')} className="btn-concluir">
                                Concluir
                            </button>
                            <button onClick={() => updateConsultaStatus(consulta.id, 'faltou')} className="btn-faltou">
                                Faltou
                            </button>
                            </div>
                        </div>
                    </React.Fragment>
                );
            })}
        </div>
    );
};

const getDayOfWeek = (isoString) => {
    const dateObj = new Date(isoString);
    const dayOfWeek = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];
    return `${dayOfWeek[dateObj.getUTCDay()]} ${dateObj.getUTCDate()}/${dateObj.getUTCMonth() + 1}/${dateObj.getUTCFullYear()}`;
};

const formatDateAndTime = (isoString) => {
    const dateObj = new Date(isoString);
    const date = dateObj.toLocaleDateString('pt-BR');
    const time = dateObj.toLocaleTimeString('pt-BR', { timeZone: "America/Sao_Paulo", hour: '2-digit', minute:'2-digit' });
    return `${date} às ${time}`;
};

export default Agenda;
